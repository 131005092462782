import React from "react"
import Layout from "../layouts"
import SEO from "../layouts/seo"

const IndexPage = (props) => (
  <Layout location={props.location}>
    <div className="tool">
      <SEO title="Início" />
      <h1>Boas vindas!</h1>
      <p>Aqui você encontrará ferramentas para pessoas desenvolvedoras!</p>
    </div>
  </Layout>
)

export default IndexPage
